import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../styles/ImageSlider.css";
import { Carousel } from "react-responsive-carousel";
function ImageSlider() {
  return (
    <div className="imageSliderContainer">
      <Carousel autoPlay infiniteLoop>
        <div>
          <img
            src="https://www.farmley.com/cdn/shop/files/May2023_Website-Home-Banner02_2000x.jpg?v=1687326806"
            alt=""
          />
        </div>
        <div>
          <img
            src="https://www.farmley.com/cdn/shop/files/Website_Panchmewa_2000x.jpg?v=1674645463"
            alt=""
          />
        </div>
        <div>
          <img
            src="https://www.farmley.com/cdn/shop/files/May2023_Website-Home-Banner_2000x.jpg?v=1687326758"
            alt=""
          />
        </div>
        <div>
          <img
            src="https://www.farmley.com/cdn/shop/files/Nuts_Web_2000x.jpg?v=1674644890"
            alt=""
          />
        </div>
      </Carousel>
    </div>
  );
}

export default ImageSlider;
