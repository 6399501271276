import "./App.css";
import Body from "../src/components/Body";
import { Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import ShopByCategory from "./components/ShopByCategory";
import NewLaunches from "./components/NewLaunches";
import Gifting from "./components/Gifting";
import ContactUs from "./components/ContactUs";
import Blog from "./components/Blog";
import Footer from "./components/Footer";
import SearchBox from "./components/SearchBox";
import Cart from "./components/Cart";
import LoginComponent from "./components/LoginComponent";
import SignUpComponent from "./components/SignUpComponent";
import ErrorPage from "./components/ErrorPage";
import AboutUs from "./components/AboutUs";
import ChatBot from "react-simple-chatbot";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Body />} />
        <Route
          path="shopbycategory"
          element={
            <>
              <SearchBox />
              <ShopByCategory />
            </>
          }
        />
        <Route
          path="newlaunches"
          element={
            <>
              <SearchBox />
              <NewLaunches />
            </>
          }
        />
        <Route
          path="gifting"
          element={
            <>
              <SearchBox />
              <Gifting />
            </>
          }
        />
        <Route
          path="contactus"
          element={
            <>
              <ContactUs />
            </>
          }
        />
        <Route
          path="blog"
          element={
            <>
              <Blog />
            </>
          }
        />
        <Route
          path="cart"
          element={
            <>
              <Cart />
            </>
          }
        />
        <Route
          exact="true"
          path="login"
          element={
            <>
              <LoginComponent />
            </>
          }
        />
        <Route exact="true" path="aboutus" element={<AboutUs />}></Route>
        <Route
          exact="true"
          path="signup"
          element={
            <>
              <SignUpComponent />
            </>
          }
        />
        <Route exact="true" path="error" element={<ErrorPage />}></Route>
        <Route path="*" element={<Navigate to="/error" />} />
      </Routes>
      <ChatBot
        steps={[
          {
            id: "1",
            message: "What is your name?",
            trigger: "2",
          },
          {
            id: "2",
            user: true,
            trigger: "3",
          },
          {
            id: "3",
            message:
              "Hello {previousValue}, I am nutriiBot your virtual assistant, happy to help you! ",
            trigger: "4",
            // end: true,
          },
          {
            id: "4",
            message: "Please select below options, what issue you are facing!",
            trigger: "5",
          },
          {
            id: "5",
            options: [
              { value: 1, label: "Track Order", trigger: "6" },
              { value: 2, label: "Account Related Issue", trigger: "7" },
              { value: 3, label: "Return Policy", trigger: "8" },
              { value: 4, label: "Other", trigger: "9" },
            ],
          },
          {
            id: "6",
            message:
              "For tracking your order please enter your tracking code or you can directly check on delhivery.com",
            trigger: "10",
          },
          {
            id: "7",
            message:
              "You can update your account information directly from the profile section. having issue then mail at nutriinuts@gmail.com",
            trigger: "10",
          },
          {
            id: "8",
            message:
              "For return policy, please read our return policy, if something else please mail at nutriinuts@gmail.com",
            trigger: "10",
          },
          {
            id: "9",
            message:
              "For other issues, write us on nutriinuts@gmail.com or call 9992047272. We will reach you within 24 hours",
            trigger: "10",
          },
          {
            id: "10",
            message: "Thank You! Have a good day!",
            // end: true,
            trigger: "5",
          },
        ]}
        floating={true}
      />
      <Footer />
    </div>
  );
}

export default App;
