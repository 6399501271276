import React from "react";
import "../styles/Blog.css";

function Blog() {
  return (
    <div className="blogPageMainDiv">
      <div className="headingBlogContainer">
        <h4>Towards A Healthier Tomorrow</h4>
      </div>
      <div className="blogContainer">
        <div className="blogDiv">
          <div className="blogImageDiv">
            <img
              src="https://www.farmley.com/cdn/shop/articles/APRICOT-BLOG-BANNER_800x.png?v=1673944372"
              alt="blog_postImg"
            />
          </div>
          <div className="blogTagLine">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </div>
          <div className="blogDivContent">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Neque
            temporibus laborum doloribus quam? Nam animi porro iusto, similique
            aperiam excepturi rem mollitia illum sequi quis sit! Consequuntur,
            ab ex esse tenetur amet temporibus rerum sint repellendus
            dignissimos voluptates dolorum quas aliquid expedita aspernatur
            ratione tempora! Esse?
          </div>
        </div>
        <div className="blogDiv">
          <div className="blogImageDiv">
            <img
              src="https://www.farmley.com/cdn/shop/articles/APRICOT-BLOG-BANNER_800x.png?v=1673944372"
              alt="blog_postImg"
            />
          </div>
          <div className="blogTagLine">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </div>
          <div className="blogDivContent">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Neque
            temporibus laborum doloribus quam? Nam animi porro iusto, similique
            aperiam excepturi rem mollitia illum sequi quis sit! Consequuntur,
            ab ex esse tenetur amet temporibus rerum sint repellendus
            dignissimos voluptates dolorum quas aliquid expedita aspernatur
            ratione tempora! Esse?
          </div>
        </div>
        <div className="blogDiv">
          <div className="blogImageDiv">
            <img
              src="https://www.farmley.com/cdn/shop/articles/APRICOT-BLOG-BANNER_800x.png?v=1673944372"
              alt="blog_postImg"
            />
          </div>
          <div className="blogTagLine">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </div>
          <div className="blogDivContent">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Neque
            temporibus laborum doloribus quam? Nam animi porro iusto, similique
            aperiam excepturi rem mollitia illum sequi quis sit! Consequuntur,
            ab ex esse tenetur amet temporibus rerum sint repellendus
            dignissimos voluptates dolorum quas aliquid expedita aspernatur
            ratione tempora! Esse?
          </div>
        </div>
        <div className="blogDiv">
          <div className="blogImageDiv">
            <img
              src="https://www.farmley.com/cdn/shop/articles/APRICOT-BLOG-BANNER_800x.png?v=1673944372"
              alt="blog_postImg"
            />
          </div>
          <div className="blogTagLine">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </div>
          <div className="blogDivContent">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Neque
            temporibus laborum doloribus quam? Nam animi porro iusto, similique
            aperiam excepturi rem mollitia illum sequi quis sit! Consequuntur,
            ab ex esse tenetur amet temporibus rerum sint repellendus
            dignissimos voluptates dolorum quas aliquid expedita aspernatur
            ratione tempora! Esse?
          </div>
        </div>
        <div className="blogDiv">
          <div className="blogImageDiv">
            <img
              src="https://www.farmley.com/cdn/shop/articles/APRICOT-BLOG-BANNER_800x.png?v=1673944372"
              alt="blog_postImg"
            />
          </div>
          <div className="blogTagLine">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </div>
          <div className="blogDivContent">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Neque
            temporibus laborum doloribus quam? Nam animi porro iusto, similique
            aperiam excepturi rem mollitia illum sequi quis sit! Consequuntur,
            ab ex esse tenetur amet temporibus rerum sint repellendus
            dignissimos voluptates dolorum quas aliquid expedita aspernatur
            ratione tempora! Esse?
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
