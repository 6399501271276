import React from "react";
import { useState } from "react";
import styles from "../styles/Navbar.module.css";
import { Link } from "react-router-dom";
import cartLogo from "../assets/cartLogo.png";
import userLogo from "../assets/userLogo.png";
import companyLogo from "../assets/pngLogo.jpg";

function Navbar() {
  // adding the states
  const [isActive, setIsActive] = useState(false);
  //add the active class
  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };
  //clean up function to remove the active class
  const removeActive = () => {
    setIsActive(false);
  };
  return (
    <div className="App">
      <header className="App-header">
        <nav className={`${styles.navbar}`}>
          {/* logo */}
          <Link to="/" className={`${styles.logo}`}>
            <img className="companyLogo" src={companyLogo} alt="logo" />
            <h4>NutriiNuts</h4>
          </Link>
          <ul className={`${styles.navMenu} ${isActive ? styles.active : ""}`}>
            <li onClick={removeActive}>
              <Link to="/" className={`${styles.navLink}`}>
                Home
              </Link>
            </li>
            <li onClick={removeActive}>
              <Link to="/shopbycategory" className={`${styles.navLink}`}>
                Shop by Category
              </Link>
            </li>
            <li onClick={removeActive}>
              <Link to="/newlaunches" className={`${styles.navLink}`}>
                New Launches
              </Link>
            </li>
            <li onClick={removeActive}>
              <Link to="/gifting" className={`${styles.navLink}`}>
                Gifting
              </Link>
            </li>
            <li onClick={removeActive}>
              <Link to="/contactus" className={`${styles.navLink}`}>
                Contact Us
              </Link>
            </li>
            <li onClick={removeActive}>
              <Link to="/blog" className={`${styles.navLink}`}>
                Blog
              </Link>
            </li>
          </ul>
          <div className={`${styles.userCartContainer}`}>
            <div className={`${styles.cartBoxContainer}`}>
              <div>
                <Link to="/cart">
                  <img
                    className={`${styles.cartLogoImg}`}
                    src={cartLogo}
                    alt=""
                  />
                </Link>
              </div>
              <div>
                <Link to="/cart">
                  <span>(0)</span>
                </Link>
              </div>
            </div>

            {/* Navbar dropdown  */}

            <div className="userBoxContainer">
              <Link to="/login">
                <img src={userLogo} alt="" />
              </Link>
            </div>
          </div>
          <div
            className={`${styles.hamburger} ${isActive ? styles.active : ""}`}
            onClick={toggleActiveClass}
          >
            <span className={`${styles.bar}`}></span>
            <span className={`${styles.bar}`}></span>
            <span className={`${styles.bar}`}></span>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Navbar;
