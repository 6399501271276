import React from "react";
import "../styles/PromotionalBanner.css";

function PromotionalBanner() {
  return (
    <div className="marquee">
      <div className="insideMarqueeContainer">
        <span className="firstSpan">
          Get 8% Discount on Purchase of 1199 and Above With Code "SAVER8"
        </span>
      </div>
    </div>
    // <div className="promotionalCouponMainContainer">
    //   <h6 className="promotionalCoupon">
    //     Get 5% Discount on Purchase of 1199 using "SAVER5"
    //   </h6>
    // </div>
  );
}

export default PromotionalBanner;
