import React from "react";
import AllProductPage from "./AllProductPage";
import "../styles/NewLaunches.css";

function NewLaunches() {
  return (
    <div>
      <div className="newLaunchHeading">
        <span>New Launches</span>
      </div>
      <div className="newLaunchContainer">
        <AllProductPage />
      </div>
    </div>
  );
}

export default NewLaunches;
