import React from "react";
import "../styles/ContactUs.css";

function ContactUs() {
  return (
    <div className="contactUsMainContainer">
      <div className="contactUsDivHeading">
        <h1>Contact Us</h1>
      </div>
      <div className="feedbackFormDiv">
        <div className="feedbackFormDivHeading">
          <h1>Feedback or Query Form!!</h1>
        </div>
        <div className="feedbackInputBox">
          <input type="text" placeholder="Enter Your Name" />
          <input type="email" placeholder="Enter Your Email ID" />
          <input type="text" placeholder="Enter Your Phone Number" />
          <textarea
            type="text"
            placeholder="Enter Your Feedback or Query"
            rows={12}
            cols={34}
          />
          <button className="feedbackSubmitBtn">Submit</button>
        </div>
      </div>
    </div>
  );
  //Basic Form Creation
  //Phone EMAIL Query..

  //Feedback Link
}

export default ContactUs;
