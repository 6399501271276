import React from "react";
// import SearchBox from "./SearchBox";
import PromotionalBanner from "./PromotionalBanner";
import ImageSlider from "./ImageSlider";
import AllProductPage from "./AllProductPage";
import TestimonialPage from "./TestimonialPage";
import "../styles/Body.css";
function Body() {
  return (
    <div>
      {/* <SearchBox /> */}
      <PromotionalBanner />
      <ImageSlider />
      <AllProductPage />
      <TestimonialPage />
    </div>
  );
}

export default Body;
