import React from "react";
import "../styles/FilterComponent.css";

function FilterComponent() {
  return (
    <div className="filterComponentContainerOne">
      <div className="firstComponent">
        <span>Shop By Category</span>
      </div>
      <hr className="borderComponentDivider" />
      <div className="checkBoxCategoryComponent">
        <label class="checkBoxLabelContainer">
          <input type="checkbox" />
          <span class="checkmark">Makhanas (Fox Nuts)</span>
        </label>
        <label class="checkBoxLabelContainer">
          <input type="checkbox" />
          <span class="checkmark">Almonds</span>
        </label>
        <label class="checkBoxLabelContainer">
          <input type="checkbox" />
          <span class="checkmark">Cashew Nuts</span>
        </label>
        <label class="checkBoxLabelContainer">
          <input type="checkbox" />
          <span class="checkmark">Dips</span>
        </label>
      </div>

      <div className="filterButtonContainer --close">
        <button className="filterBtn ">Filter</button>
        <button className="resetBtn">Reset</button>
      </div>
    </div>
  );
}

export default FilterComponent;
