import React from "react";
import "../styles/SignUpComponent.css";
import pngLogo from "../assets/pngLogo.jpg";
import LoginSignUpUpperComponent from "./LoginSignUpUpperComponent";
// import { Link } from "react-router-dom";

function SignUpComponent() {
  return (
    <div className="loginComponentContainer">
      <div className="innerNavigationBox">
        <LoginSignUpUpperComponent />
      </div>
      <div className="insideContainer card1">
        <div className="logoDivBox">
          <div>
            <img className="imgPngLogo" src={pngLogo} alt="" />
          </div>
          <div className="titleBoxLogo">
            <span>NutriiNuts</span>
          </div>
        </div>
        <hr className="borderComponentDivider" />
        <div className="loginTextBox">
          <span>SignUp</span>
        </div>
        <div className="loginInputField">
          <input type="text" placeholder="Enter Your Name" />
          <input type="text" placeholder="Enter Your Email" />
          <input type="text" placeholder="Enter Your Password" />
        </div>
        <div className="loginBtnContainer">
          <button>Sign Up</button>
        </div>
        {/* <div className="signupPageNavigation">
          <span>
            Not a User?{" "}
            <Link className="loginPageNav" to="/login/signup/login">
              Login
            </Link>
          </span>
        </div> */}
      </div>
    </div>
  );
}

export default SignUpComponent;
