import React from "react";
import "../styles/LoginComponent.css";
import pngLogo from "../assets/pngLogo.jpg";
import LoginSignUpUpperComponent from "./LoginSignUpUpperComponent";
// import { Link } from "react-router-dom";

function LoginComponent() {
  return (
    <div className="loginComponentContainer">
      <div className="innerNavigationBox">
        <LoginSignUpUpperComponent />
      </div>
      <div className="insideContainer card1">
        <div className="logoDivBox">
          <div>
            <img className="imgPngLogo" src={pngLogo} alt="" />
          </div>
          <div className="titleBoxLogo">
            <span>NutriiNuts</span>
          </div>
        </div>
        <hr className="borderComponentDivider" />
        <div className="loginTextBox">
          <span>Login</span>
        </div>
        <div className="loginInputField">
          <input type="text" placeholder="Enter Your Email" />
          <input type="text" placeholder="Enter Your Password" />
        </div>
        <div className="rememberInputBox">
          <input type="checkbox" name="rememberme" id="" />
          <span>Remember Me</span>
        </div>
        <div className="loginBtnContainer">
          <button>Login</button>
        </div>
        {/* <div className="signupPageNavigation">
          <span>
            Not a User? <Link to="signup">Sign Up</Link>
          </span>
        </div> */}
      </div>
    </div>
  );
}

export default LoginComponent;
