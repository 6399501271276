import React from "react";
import "../styles/TestimonialPage.css";

function TestimonialPage() {
  return (
    <div className="testimonialPageContainer">
      <img
        src="https://www.farmley.com/cdn/shop/files/FINALLLLLL_1800x.jpg?v=1674649795"
        alt=""
      />
    </div>
  );
}

export default TestimonialPage;
