import React from "react";
import blankCart from "../assets/emptyCart.svg";
import "../styles/Cart.css";

function Cart() {
  return (
    <div className="mainCartContainer">
      <div className="blankCartContainer">
        <img src={blankCart} alt="" />
        <div className="cartHeading">
          <h1>Your Cart is Empty!!</h1>
        </div>
      </div>
    </div>
  );
}

export default Cart;
