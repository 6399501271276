import React from "react";
import { Link } from "react-router-dom";
import "../styles/LoginSignUpUpperComponent.css";

function LoginSignUpUpperComponent() {
  return (
    <div className="loginSignUpComponent">
      <div className="loginInnerBox">
        <Link to="/login">
          <button className="loginBtn">Login</button>
        </Link>
      </div>
      <div className="signUpInnerBox">
        <Link to="/signup">
          <button className="signupBtn">SignUp</button>
        </Link>
      </div>
    </div>
  );
}

export default LoginSignUpUpperComponent;
