import React from "react";
import FilterComponent from "./FilterComponent";
import AllProductPage from "./AllProductPage";
import "../styles/ShopByCategory.css";
function ShopByCategory() {
  return (
    <div className="shopByCategoryContainer">
      <div className="filterComponentContainer">
        <FilterComponent />
      </div>
      <div className="productCardComponent">
        <AllProductPage />
      </div>
    </div>
  );
}

export default ShopByCategory;
