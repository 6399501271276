import React from "react";
import "../styles/Gifting.css";
import AllProductPage from "./AllProductPage";

function Gifting() {
  return (
    <div className="giftingMainContainer">
      <div className="giftingHeading">
        <span>Gifting</span>
      </div>
      <div className="giftingContainer">
        <AllProductPage />
      </div>
    </div>
  );
}

export default Gifting;
